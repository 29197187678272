// extracted by mini-css-extract-plugin
export var carouselContainer = "H_vK d_w d_H d_bf d_Z";
export var carouselContainerCards = "H_vL H_vK d_w d_H d_bf d_Z";
export var carouselContainerSides = "H_vM d_w d_H d_Z";
export var prevCarouselItem = "H_vN d_w d_H d_0 d_k";
export var prevCarouselItemL = "H_vP H_vN d_w d_H d_0 d_k";
export var moveInFromLeft = "H_vQ";
export var prevCarouselItemR = "H_vR H_vN d_w d_H d_0 d_k";
export var moveInFromRight = "H_vS";
export var selectedCarouselItem = "H_vT d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "H_vV H_vT d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "H_vW H_vT d_w d_H d_Z d_bf";
export var nextCarouselItem = "H_vX d_w d_H d_0 d_k";
export var nextCarouselItemL = "H_vY H_vX d_w d_H d_0 d_k";
export var nextCarouselItemR = "H_vZ H_vX d_w d_H d_0 d_k";
export var arrowContainer = "H_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "H_v1 H_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "H_v2 H_v1 H_v0 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "H_v3 H_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "H_v4";
export var nextArrowContainerHidden = "H_v5 H_v3 H_v0 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "H_kG d_0";
export var prevArrow = "H_v6 H_kG d_0";
export var nextArrow = "H_v7 H_kG d_0";
export var carouselIndicatorContainer = "H_v8 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "H_v9 d_w d_bz d_bF";
export var carouselText = "H_wb d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "H_wc H_wb d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "H_wd d_b7";
export var carouselIndicator = "H_wf H_wd d_b7";
export var carouselIndicatorSelected = "H_wg H_wd d_b7";
export var arrowsContainerTopRight = "H_wh d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "H_wj d_0 d_bl d_bC";
export var arrowsContainerSides = "H_wk d_0 d_bl d_bC";
export var smallArrowsBase = "H_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "H_wm H_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "H_wn H_wm H_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "H_wp H_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "H_wq H_wp H_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "H_p9";
export var iconSmall = "H_wr";
export var multipleWrapper = "H_ws d_bC d_bF d_bf";
export var multipleImage = "H_wt d_bC";
export var sidesPrevContainer = "H_wv H_wm H_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "H_ww H_wm H_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";