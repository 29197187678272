// extracted by mini-css-extract-plugin
export var lbContainerOuter = "J_wx";
export var lbContainerInner = "J_wy";
export var movingForwards = "J_wz";
export var movingForwardsOther = "J_wB";
export var movingBackwards = "J_wC";
export var movingBackwardsOther = "J_wD";
export var lbImage = "J_wF";
export var lbOtherImage = "J_wG";
export var prevButton = "J_wH";
export var nextButton = "J_wJ";
export var closing = "J_wK";
export var appear = "J_wL";